const awsEnv = process.env.REACT_APP_AWS_PROFILE.split("-").pop() // Return just the environment (jarjar, qa, production)
export const APIGEE_ENV = awsEnv === 'jarjar' ? 'dev' : awsEnv

// Temporary fix to allow apig-config console via creds - Shoud be reverted after myapps login fix
//export const prodEnv = APIGEE_ENV === 'production'
//export const DOMAIN = prodEnv ? `apigee.paybyphoneapis.com` : `apigee.${APIGEE_ENV}.paybyphoneapis.dev`
export const prodEnv = false;
export const DOMAIN = APIGEE_ENV === 'production' ? `apigee.paybyphoneapis.com` : `apigee.${APIGEE_ENV}.paybyphoneapis.dev`

export const PROTOCOL = 'https'
export const BASE_PATH = 'gateway-config'
export const ENDPOINT = `${DOMAIN}/${BASE_PATH}`
// export const authority = "https://paybyphone.onelogin.com/oidc/2";
export const authority = "https://login.microsoftonline.com/f09e6bf1-a7ad-4414-a6c0-a79c6ab40caa/oauth2/v2.0";
export const client_id = `${process.env.REACT_APP_CONFIG_CONSOLE_CLIENT_ID}`;
export const response_type = "id_token token";
export const scope = "openid profile";
export const redirect_uri = `${process.env.REACT_APP_CONSOLE_URL}/auth`
export const post_logout_redirect_uri = `${process.env.REACT_APP_CONSOLE_URL}/home`
export const DATADOG_URL = "https://app.datadoghq.com/dashboard/cb7-8cp-qsb/apigee-consumer-passthrough-rev2";
export const CONFLUENCE_URL = "https://paybyphonetechnologies.atlassian.net/wiki/spaces/APIC/pages/627474566/Apigee+Config+Console+-+Rate+Limiting+and+Throttling+Guide";

